<template>
  <b-modal
    v-model="showModal"
    :title="title"
    :size="displayDeliveryOptions ? 'lg' : 'sm'"
    centered
    no-close-on-backdrop
  >
    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div
        v-if="typeof loadingError === 'object'"
        class="alert-body"
      >
        <p
          v-for="(message, index) in loadingError"
          :key="index"
        >
          {{ message }}
        </p>
      </div>
      <p
        v-else
        class="alert-body"
      >
        {{ loadingError }}
      </p>
    </b-alert>

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-form
      v-if="!loading && !loadingError"
      @submit.prevent="submit"
    >
      <validation-observer
        ref="statusForm"
      >
        <b-row>
          <b-col :md="displayDeliveryOptions ? 6 : 12">
            <b-form-group
              :label="label"
            >
              <custom-v-select
                v-model="selectedUser"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                label="fullname"
                placeholder="Select User"
                :reduce="(data) => data.id"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="displayDeliveryOptions"
            md="6"
          >
            <validation-provider
              #default="{ errors }"
              name="Delivery Mode"
              vid="delivery_mode"
              rules="required"
            >
              <b-form-group
                label="Delivery Mode"
                :state="errors.length > 0 ? false : null"
              >
                <custom-v-select
                  v-model="deliveryMode"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="deliveryModeOptions"
                  label="label"
                  placeholder="Select Delivery Mode"
                  :reduce="(data) => data.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <div v-if="displayDeliveryOptions">
          <b-card
            v-if="deliveryMode === 'TRANSPORT_DELIVERY'"
            no-body
            class="p-1"
          >
            <div
              @click="expandFillSection = !expandFillSection"
            >
              <div class="d-flex align-items-center text-primary font-weight-bold cursor-pointer">
                <feather-icon
                  v-if="expandFillSection"
                  icon="ChevronDownIcon"
                  size="20"
                />
                <feather-icon
                  v-else
                  icon="ChevronRightIcon"
                  size="20"
                />
                <div>
                  Fill Bus Details
                </div>
              </div>
            </div>
            <b-card-text>
              <div
                v-if="expandFillSection"
                class="mt-1"
              >
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Bus"
                    >
                      <custom-v-select
                        v-model="selectedBus"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="busOptions"
                        label="name_and_number"
                        placeholder="Select Bus"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Conductor"
                    >
                      <custom-v-select
                        v-model="selectedConductor"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="conductorOptions"
                        label="title"
                        placeholder="Select Conductor"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right">
                    <b-button
                      size="sm"
                      variant="primary"
                      :disabled="!selectedBus"
                      @click="fillBusDeatils"
                    >
                      Fill Details
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-card-text>
          </b-card>

          <b-row>
            <b-col
              v-if="additionalFields.includes('bus_name_number')"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Bus Name and Number"
                vid="bus_name_number"
                rules="required|max:60"
              >
                <b-form-group
                  label="Bus Name and Number"
                >
                  <b-form-input
                    v-model="busNameNumber"
                    type="text"
                    placeholder="Bus Name and Number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="additionalFields.includes('conductor_name')"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Conductor Name"
                vid="conductor_name"
                rules="required|max:30"
              >
                <b-form-group
                  label="Conductor Name"
                >
                  <b-form-input
                    v-model="conductorName"
                    type="text"
                    placeholder="Conductor Name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              v-if="additionalFields.includes('conductor_mobile')"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Conductor Mobile No."
                vid="conductor_mobile"
                rules="required|max:15"
              >
                <b-form-group
                  label="Conductor Mobile No."
                >
                  <b-form-input
                    v-model="conductorMobile"
                    type="text"
                    placeholder="Conductor Mobile No."
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="additionalFields.includes('receiving_time')"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Receiving Time"
                vid="receiving_time"
                rules="required"
              >
                <b-form-group
                  label="Receiving Time"
                  :state="errors.length > 0 ? false:null"
                >
                  <FlatPicker
                    v-model="receivingTime"
                    :config="{ enableTime: true }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              v-if="additionalFields.includes('destination_address')"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Destination Address"
                vid="destination_address"
                rules="required"
              >
                <b-form-group
                  label="Destination Address"
                >
                  <b-form-textarea
                    v-model="destinationAddress"
                    rows="3"
                    type="text"
                    placeholder="Destination Address"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="additionalFields.includes('remarks')"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Remarks"
                vid="remarks"
                rules="required"
              >
                <b-form-group
                  label="Remarks"
                >
                  <b-form-textarea
                    v-model="remarks"
                    rows="3"
                    type="text"
                    placeholder="Remarks"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="additionalFields.includes('bus_photo')"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Bus Photo"
                vid="bus_photo"
                rules=""
              >
                <b-form-group
                  label="Bus Photo"
                >
                  <b-form-file
                    v-model="busPhoto"
                    :state="errors.length > 0 ? false:null"
                    accept="image/*"
                    :disabled="useMasterBusPhoto"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <div class="mb-50">
                <b-form-checkbox
                  v-model="useMasterBusPhoto"
                  :disabled="!masterBusPhotoUrl"
                >
                  Use bus photo from master
                </b-form-checkbox>
              </div>

              <div v-if="masterBusPhotoUrl">
                <a
                  :href="masterBusPhotoUrl"
                  target="_blank"
                >
                  <span>View Master Bus Photo</span>
                </a>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              v-if="additionalFields.includes('mr_name')"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="MR Name"
                vid="mr_name"
                rules="required|max:30"
              >
                <b-form-group
                  label="MR Name"
                >
                  <b-form-input
                    v-model="mrName"
                    type="text"
                    placeholder="MR Name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="additionalFields.includes('mr_number')"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="MR Number"
                vid="mr_number"
                rules="max:30"
              >
                <b-form-group
                  label="MR Number"
                >
                  <b-form-input
                    v-model="mrNumber"
                    type="text"
                    placeholder="MR Number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

        </div>

      </validation-observer>
    </b-form>

    <b-alert
      variant="danger"
      :show="submitionError !== null ? true : false"
    >
      <div
        v-if="typeof submitionError === 'string'"
        class="alert-body"
      >
        <p>
          {{ submitionError }}
        </p>
      </div>
      <template v-if="submitionError && submitionError.detail">
        <div
          v-if="typeof submitionError.detail === 'object'"
          class="alert-body"
        >
          <p
            v-for="(message, index) in submitionError.detail"
            :key="index"
          >
            {{ message }}
          </p>
        </div>
        <p
          v-else
          class="alert-body"
        >
          {{ submitionError.detail }}
        </p>
      </template>

    </b-alert>

    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button
          class="mr-1"
          variant="secondary"
          @click="showModal=false"
        >
          Close
        </b-button>
        <b-button
          variant="primary"
          :disabled="loading || !!loadingError || submitting"
          @click="handleConfirm"
        >
          {{ confirmButtonText }}
          <b-spinner
            v-if="submitting"
            small
            label="Small Spinner"
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BSpinner, BAlert, BFormGroup, BButton, BFormInput, BFormTextarea, BRow, BCol, BForm, BFormFile, BCard, BCardText, BFormCheckbox,
} from 'bootstrap-vue'
import axios from 'axios'
import moment from 'moment-timezone'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
// eslint-disable-next-line no-unused-vars
import { max } from '@/custom-validations'

export default {
  components: {
    BModal,
    BSpinner,
    BAlert,
    BFormGroup,
    CustomVSelect,
    BButton,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    FlatPicker,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormFile,
    BCard,
    BCardText,
    BFormCheckbox,
  },
  props: {
    value: { type: Boolean, default: false },
    mode: { type: String, required: true },
    billIds: { type: Array, required: true },
    busOptions: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      loading: false,
      submitting: false,
      loadingError: null,
      submitionError: null,
      userOptions: [],
      deliveryModeOptions: [
        {
          value: 'SELF_COLLECTION',
          label: 'Self Collection',
        },
        {
          value: 'DOOR_DELIVERY',
          label: 'Door Delivery',
        },
        {
          value: 'MR_COLLECTION',
          label: 'Mr Collection',
        },
        {
          value: 'TRANSPORT_DELIVERY',
          label: 'Transport Delivery',
        },
        {
          value: 'DELIVERED_TO_OTHER_WHOLESALER',
          label: 'Delivered to Other Wholesaler',
        },
        {
          value: 'DELIVERED_TO_OTHER_RETAILER',
          label: 'Delivered to other retailer',
        },
        {
          value: 'DELIVERD_WITH_REMARK',
          label: 'Delivered with remark',
        },
      ],
      selectedUser: null,
      deliveryMode: 'SELF_COLLECTION',
      busNameNumber: null,
      conductorName: null,
      conductorMobile: null,
      receivingTime: null,
      destinationAddress: null,
      remarks: null,
      busPhoto: null,
      mrName: null,
      mrNumber: null,
      expandFillSection: true,
      selectedBus: null,
      selectedConductor: null,
      masterBusPhotoUrl: null,
      useMasterBusPhoto: false,
      masterBusId: null,
    }
  },
  computed: {
    showModal: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
    title() {
      if (this.mode === 'PICK') {
        return 'Pick Bill'
      }
      if (this.mode === 'DELIVER') {
        return 'Deliver Bill'
      }
      if (this.mode === 'PICK+DELIVER') {
        return 'Pick & Deliver Bill'
      }

      return ''
    },
    label() {
      if (this.mode === 'PICK') {
        return '[Optional] Select pickup person'
      }
      if (this.mode === 'DELIVER') {
        return '[Optional] Select delivery person'
      }
      if (this.mode === 'PICK+DELIVER') {
        return '[Optional] Select pickup & delivery person'
      }

      return ''
    },
    confirmButtonText() {
      if (this.mode === 'PICK') {
        return 'Confirm Pick'
      }
      if (this.mode === 'DELIVER') {
        return 'Confirm Deliver'
      }
      if (this.mode === 'PICK+DELIVER') {
        return 'Confirm Pick & Deliver'
      }

      return ''
    },
    displayDeliveryOptions() {
      return (this.mode === 'DELIVER' || this.mode === 'PICK+DELIVER')
    },
    additionalFields() {
      if (this.deliveryMode === 'TRANSPORT_DELIVERY') {
        return ['bus_name_number', 'conductor_name', 'conductor_mobile', 'receiving_time', 'destination_address', 'bus_photo']
      }

      if (this.deliveryMode === 'MR_COLLECTION') {
        return ['mr_name', 'mr_number']
      }

      if (['DELIVERED_TO_OTHER_WHOLESALER', 'DELIVERED_TO_OTHER_RETAILER', 'DELIVERD_WITH_REMARK'].includes(this.deliveryMode)) {
        return ['remarks']
      }

      return []
    },
    conductorOptions() {
      if (!this.selectedBus) {
        return []
      }

      return this.selectedBus.conductors.map(coductor => ({
        ...coductor,
        title: `${coductor.name} (${coductor.mobile})`,
      }))
    },
  },
  watch: {
    selectedBus: {
      deep: true,
      handler() {
        this.selectedConductor = this.conductorOptions.length > 0 ? this.conductorOptions[0] : null
      },
    },
  },
  mounted() {
    this.fetchOptions()
  },
  methods: {
    async fetchOptions() {
      this.loading = true
      this.loadingError = null

      try {
        await axios.post('/delivery/update_bills_status', {
          bills_delivery_id: this.billIds,
          action: this.mode,
          dry_run: true,
        })
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Failed to check bill status!'
        this.loading = false
        return
      }

      try {
        const { data } = await axios.get('/delivery/delivery_group_users')
        this.userOptions = data
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching users!'
        this.loading = false
      }

      this.loading = false
    },
    async handleConfirm() {
      const isFormValid = await this.$refs.statusForm.validate()

      if (!isFormValid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please correct the form errors',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.submitionError = null
      this.submitting = true

      const formData = new FormData()

      for (let x = 0; x < this.billIds.length; x += 1) {
        formData.append('bills_delivery_id', this.billIds[x])
      }
      formData.append('action', this.mode)

      if (this.selectedUser) {
        formData.append('action_user', this.selectedUser)
      }
      if (this.deliveryMode) {
        formData.append('delivery_mode', this.deliveryMode)
      }
      if (this.additionalFields.includes('bus_name_number') && this.busNameNumber) {
        formData.append('bus_name_number', this.busNameNumber)
      }
      if (this.additionalFields.includes('conductor_name') && this.conductorName) {
        formData.append('conductor_name', this.conductorName)
      }
      if (this.additionalFields.includes('conductor_mobile') && this.conductorMobile) {
        formData.append('conductor_mobile', this.conductorMobile)
      }
      if (this.additionalFields.includes('receiving_time') && this.receivingTime) {
        formData.append('receiving_time', this.receivingTime)
      }
      if (this.additionalFields.includes('destination_address') && this.destinationAddress) {
        formData.append('destination_address', this.destinationAddress)
      }
      if (this.additionalFields.includes('remarks') && this.remarks) {
        formData.append('remarks', this.remarks)
      }
      if (this.additionalFields.includes('bus_photo') && this.busPhoto) {
        formData.append('bus_photo', this.busPhoto)
      }
      if (this.additionalFields.includes('mr_name') && this.mrName) {
        formData.append('mr_name', this.mrName)
      }
      if (this.additionalFields.includes('mr_number') && this.mrNumber) {
        formData.append('mr_number', this.mrNumber)
      }
      formData.append('use_master_bus_photo', this.useMasterBusPhoto)
      if (this.useMasterBusPhoto) {
        formData.append('master_bus_id', this.masterBusId)
      }

      axios.post('/delivery/update_bills_status', formData).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.detail,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        this.$refs.statusForm.reset()
        this.submitting = false
        this.$emit('updated')
        this.showModal = false
      }).catch(error => {
        this.$refs.statusForm.reset()

        const statusCode = error?.response?.status
        const serverErrors = error?.response?.data
        if (statusCode === 400 && serverErrors) {
          this.$refs.statusForm.setErrors(serverErrors)
          this.submitionError = serverErrors?.detail
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please correct the form errors',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } else {
          this.submitionError = serverErrors?.detail || 'Failed to update the status!'
        }

        this.submitting = false
      })
    },
    fillBusDeatils() {
      const bus = this.selectedBus
      const conductor = this.selectedConductor

      this.busNameNumber = bus.name_and_number
      this.conductorName = conductor?.name
      this.conductorMobile = conductor?.mobile
      this.destinationAddress = bus.destination_address
      this.masterBusPhotoUrl = bus.photo_url
      this.useMasterBusPhoto = !!bus.photo_url
      this.masterBusId = bus.id

      if (bus.receiving_time) {
        const datetime = moment
          .tz(bus.receiving_time, 'HH:mm:ss', 'Asia/Kolkata')
          .format('YYYY-MM-DD HH:mm')

        this.receivingTime = datetime
      } else {
        this.receivingTime = null
      }

      this.expandFillSection = false
    },
  },
}
</script>
