<template>
  <b-modal
    v-model="showModal"
    title="Delivery Status"
    size="lg"
    centered
    no-close-on-backdrop
    ok-only
    ok-title="Close"
    @ok="$emit('modal-closed')"
    @close="$emit('modal-closed')"
  >

    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
    >
      <div
        v-if="typeof errorMessage === 'object'"
        class="alert-body"
      >
        <p
          v-for="(message, index) in errorMessage"
          :key="index"
        >
          {{ message }}
        </p>
      </div>
      <p
        v-else
        class="alert-body"
      >
        {{ errorMessage }}
      </p>
    </b-alert>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>
    <div v-if="data && !loading">
      <b-table-simple
        responsive
        hover
        class="compact-table"
      >
        <b-tbody>
          <b-tr>
            <b-th> Bill No </b-th>
            <b-td> {{ data.bill.bill_no }} </b-td>
            <b-th> Buyer </b-th>
            <b-td> {{ data.bill.party__identifier }} </b-td>
          </b-tr>
          <b-tr>
            <b-th>Date</b-th>
            <b-td> {{ data.bill && formatedDate(data.bill.date) || '-' }} </b-td>
            <b-th> Amount </b-th>
            <b-td> {{ formateNumber(data.bill.amount) }} </b-td>
          </b-tr>
          <b-tr>
            <b-th> Mode </b-th>
            <b-td> {{ data.bill.mode }} </b-td>
            <b-th> Payment Status </b-th>
            <b-td> {{ data.bill.pay_status }} </b-td>
          </b-tr>
          <b-tr>
            <b-th> Due Amount</b-th>
            <b-td>
              {{ formateNumber(data.bill.due_amount) }}
            </b-td>
            <b-th> Attachments </b-th>
            <b-td>
              <span
                v-for="(item, index) in data.bill.bill_attachments"
                :key="index"
              >
                <a
                  v-if="index === data.bill.bill_attachments.length - 1"
                  :href="item.file"
                  target="_blank"
                >
                  <span>View File {{ index+ 1 }} </span>
                </a>
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="timeline-wrapper mt-2">
        <hr class="timeline-line">
        <b-row>
          <b-col>
            <div class="d-flex justify-content-between">
              <div class="text-center">
                <status
                  class="timeline-status"
                  data="CREATED"
                />
                <b> {{ data.generated_by }}</b>
                <div>
                  {{ data.generated_at && formatedDate(data.generated_at, 'DD-MM-YYYY LT') || '-' }}
                </div>
              </div>
              <div
                v-if="data.pick_up_time"
                class="bg-white px-1"
              >
                {{ data.pick_up_time }}
              </div>

              <div
                class="text-center"
              >
                <status
                  data="PICKED"
                  class="timeline-status"
                  :disabled="!isBillPicked"
                />
                <div v-if="isBillPicked">
                  <b> {{ data.picked_by_fullname }} </b>
                  <div class="mb-1">
                    {{ data.picked_at && formatedDate(data.picked_at, 'DD-MM-YYYY LT') || '-' }}
                  </div>
                  <b-button
                    variant="outline-primary"
                    :disabled="unpickedLoader"
                    @click="handleStatusChange('UN-PICK')"
                  >
                    UnPick
                    <b-spinner
                      v-if="unpickedLoader"
                      label="Small Spinner"
                      variant="primary"
                      small
                    />
                  </b-button>
                </div>
                <span v-else> Pending </span>
              </div>
              <div
                v-if="data.delivery_time"
                class="bg-white px-1"
              >
                {{ data.delivery_time }}
              </div>

              <div class="text-center">
                <status
                  data="DELIVERED"
                  class="timeline-status"
                  :disabled="!isBillDelivered"
                />
                <div v-if="isBillDelivered">
                  <b> {{ data.delivered_by_fullname }} </b>
                  <div class="mb-1">
                    {{ data.delivered_at && formatedDate(data.delivered_at, 'DD-MM-YYYY LT') || '-' }}
                  </div>
                  <b-button
                    variant="outline-primary"
                    :disabled="unDeliverLoader"
                    @click="handleStatusChange('UN-DELIVER')"
                  > UnDeliver
                    <b-spinner
                      v-if="unDeliverLoader"
                      label="Small Spinner"
                      variant="primary"
                      small
                    />
                  </b-button>
                </div>
                <span v-else> Pending  </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div
        v-if="data.delivery_mode"
        class="mt-2"
      >
        <BillDeliveryInfo :item="data" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BTableSimple, BTr, BTh, BTd, BTbody, BSpinner, BAlert, BButton,
} from 'bootstrap-vue'
import dateMixin from '@/mixins/dateMixin'
import Status from '@/components/Delivery/DeliveryStatus/Status.vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formatMixin from '@/mixins/formatMixin'
import BillDeliveryInfo from '../BillDeliveryInfo.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BTbody,
    Status,
    BSpinner,
    BAlert,
    BButton,
    BillDeliveryInfo,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    value: { type: Boolean, default: false },
    billId: { type: Number, required: true },
  },
  data() {
    return {
      data: null,
      loading: false,
      errorMessage: '',
      unpickedLoader: false,
      unDeliverLoader: false,
    }
  },
  computed: {
    showModal: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
    isBillPicked() {
      return this.data && (this.data.picked_at || this.data.picked_by_fullname)
    },
    isBillDelivered() {
      return this.data && (this.data.delivered_at || this.data.delivered_by_fullname)
    },
  },
  mounted() {
    this.fetchBillDetail()
  },
  methods: {
    async fetchBillDetail() {
      try {
        this.loading = true
        const { data } = await axios.get(`/delivery/delivery_bills/${this.$props.billId}/`)
        this.data = data
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Failed to View bill!'
      } finally {
        this.loading = false
      }
    },
    async handleStatusChange(status) {
      try {
        this.errorMessage = ''
        if (status === 'UN-PICK') {
          this.unpickedLoader = true
        } else if (status === 'UN-DELIVER') {
          this.unDeliverLoader = true
        }
        const { data } = await axios.post('/delivery/update_bills_status', {
          bills_delivery_id: [this.data.id],
          action: status,
        })

        await this.fetchBillDetail()
        this.$emit('onStatusChange')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: data.detail,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Failed to View bill!'
      } finally {
        this.unpickedLoader = false
        this.unDeliverLoader = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.timeline-wrapper {
    position: relative;

}
.timeline-line {
    position: absolute;
    left: 0;
    right: 0;
    top: -3px;
    width: 90%;
}

.timeline-status {
    margin-bottom: 4px;
}
</style>
