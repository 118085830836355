<template>
  <b-card>
    <b-spinner
      v-if="initialLoader"
      small
      label="Small Spinner"
    />

    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
      class="mb-0"
    >
      <div class="alert-body">
        <p>
          {{ errorMessage }}
        </p>
      </div>
    </b-alert>

    <div v-if="!initialLoader && !errorMessage">
      <div

        class="d-flex justify-content-between align-items-center"
      >
        <span v-b-toggle.missing-bill-list>
          <b-badge
            pill
            :variant="missingBills.length > 0 ? 'danger' : 'primary'"
          >
            {{ missingBills.length }}
          </b-badge>
          Missing Bills
        </span>
        <b-button
          :disabled="refreshLoader"
          variant="text"
          size="sm"
          class="p-0"
          @click.prevent="getData({isRefresh: true})"
        >
          <b-spinner
            v-if="refreshLoader"
            small
            label="Small Spinner"
          />
          <feather-icon
            v-else
            icon="RotateCcwIcon"
            size="16"
          />
        </b-button>
      </div>
      <b-collapse
        id="missing-bill-list"
        class="mt-2"
      >
        <div class="missing-bill-list">
          {{ missingBills.join(", ") }}
        </div>
      </b-collapse>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BSpinner, BAlert, BCollapse, VBToggle, BBadge, BButton,
} from 'bootstrap-vue'
import axios from 'axios'
import moment from 'moment-timezone'

export default {
  directives: {
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    BSpinner,
    BAlert,
    BCollapse,
    BBadge,
    BButton,
  },
  props: {
    needRefresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialLoader: false,
      errorMessage: null,
      financialYear: null,
      missingBills: [],
      refreshLoader: false,
    }
  },
  watch: {
    needRefresh(newVal) {
      if (newVal) {
        this.getData({ isRefresh: true })
      }
    },
  },
  created() {
    this.financialYear = this.financialYearString(this.financialStart())
    this.getData()
  },
  methods: {
    financialStart() {
      const now = moment.utc().tz('Asia/Kolkata')
      const currentYear = now.year()
      const currentMonth = now.month()

      let startYear
      if (currentMonth > 2) {
        // 0 is jan, 2 is march
        startYear = currentYear
      } else {
        startYear = currentYear - 1
      }
      return startYear
    },
    financialYearString(startYear) {
      return `${startYear}-${moment
        .utc()
        .tz('Asia/Kolkata')
        .set({ year: startYear + 1 })
        .format('YY')}`
    },
    getData(options = {}) {
      const { isRefresh = false } = options
      if (isRefresh) {
        this.refreshLoader = true
      } else {
        this.initialLoader = true
      }
      axios
        .get('accounts/missing_bills', {
          params: {
            financial_year: this.financialYear,
          },
        })
        .then(res => {
          this.missingBills = res.data.data
          this.errorMessage = null
          this.initialLoader = false
          this.refreshLoader = false
        })
        .catch(error => {
          this.errorMessage = error?.response?.data?.error || 'Error fetching missing bills'
          this.initialLoader = false
          this.refreshLoader = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.missing-bill-list {
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
